<template>
  <div class="impressum">
    <page-title class="page-title">Impressum</page-title>
    <v-page>
      <article class="text hyphenate center">
        <p><strong>Karl Grimmer MSc</strong></p>
        <p>Mariahilfer Straße 117/2/21</p>
        <p>1060 Wien</p>
        <p></p>
        <p>grimmer.karl@gmail.com</p>
        <p>+43 699 1943 49 59</p>
      </article>
    </v-page>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

export default {
  name: 'Impressum',
  components: {
    PageTitle,
    VPage,
  },
}
</script>

<style scoped>
.page-title {
  height: min(320px, 50vh, 70vw);
}

.center {
  text-align: center;
}
</style>
